<template>
  <v-card flat color="#00000000">
    <v-card-title class="register-title text-center">
      How would you like to pay?
    </v-card-title>
    <v-card-text class="payment-card-text">
      <v-list class="payment-list">
        <v-list-item-group v-model="payment.selectedPayment" active-class="active-payment">
          <v-list-item v-for="pay in paymentMethods" :value="pay" :key="pay.name" class="payment-item pr-0 my-4">
            <v-list-item-content>
              <v-list-item-title class="plan-title" v-text="pay.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn tile depressed :height="64" :width="64" @click="back()">
        <v-icon :size="48">mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn tile depressed :height="64" :width="128" class="next-button" color="primary" :disabled="!canNext" @click="next()">
        {{ nextText }}
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>
import vue from 'vue'
export default {
  name: 'Payment',
  components: {
  },
  props: ['payment', 'plan', 'preSubscribeMethod', 'completeRegistration'],
  data: () => ({
  }),
  computed: {
    basicAccount () {
      if (this.plan && this.plan.selectedPlan && this.plan.selectedPlan.price == 0 && this.plan.selectedPlan.type == 'basic') {
        return true;
      }
      return false;
    },
    paymentMethods () {
      if (this.basicAccount) {
        return [
          {
            name: 'Free Account',
            type: 'basic',
            key: 'free'
          }
        ]
      }
      // if (this.isIOS) {
      //   return [
      //     {
      //       name: 'Apple Pay',
      //       key: 'applepay',
      //       type: 'premium'
      //     }
      //   ]
      // }
      return [
        {
          name: 'Paypal',
          key: 'paypal',
          type: 'premium'
        },
        {
          name: 'Stripe (credit/debit)',
          key: 'stripe',
          type: 'premium'
        },
      ]
    },
    isIOS () {
      return this.$store.getters.getPlatform == 'iOS';
    },
    canNext () {
      if (this.payment.selectedPayment != null) {
        return true;
      }
      return false;
    },
    nextText () {
      if (this.plan && this.plan.selectedPlan) {
        return 'Pay ' + this.plan.selectedPlan.currency + this.plan.selectedPlan.price;
      }
      return '';
    },
  },
  watch: {
    paymentMethods: function (val) {
      if (val.length == 1) {
        vue.set(this.payment, 'selectedPayment', val[0]);
      }
    }
  },
  methods: {
    next: function () {
      let scope = this;

      scope.$emit('next');
    },
    back: function () {
      this.$emit('back');
    },
  },
  mounted () {
  }
}
</script>
<style lang="scss">
.active-payment {
  letter-spacing: 0px;
  border: 4px solid var(--v-primary-base);
}
.active-payment .v-list-item__content,
.active-payment .price-box{
  margin: -4px;
}
.payment-item {
  background-color: #282828;
  letter-spacing: 0px;
  height: 100px;
  text-align: center;
}
.payment-list {
  background: none !important;
}
.payment-card-text {
  // max-height: calc(100vh - 260px);
  // overflow-y: auto;
}
</style>