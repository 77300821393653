<template>
  <v-card flat color="#00000000">
    <v-container class="paypal-container" v-if="!confirmed" fluid>
      <v-row class="text-center paypal-full-text" align="center">
        <v-col>
          <div class="free-body-title">
            One last thing...
          </div>
          <div class="pa-2">
            <captcha />
          </div>
            <!-- Press confirm to checkout with PayPal. -->
        </v-col>
      </v-row>
    </v-container>
    <v-container class="paypal-container" v-else fluid>
      <v-row align="center" class="text-center paypal-text">
        <v-col>
          You will be redirected to paypal shortly...
        </v-col>
      </v-row>
      <v-row align="center" class="text-center">
        <v-col>
          <v-progress-circular :size="80" :width="2" color="primary" class="site-loader" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer/>
      <v-btn tile depressed :height="64" :width="64" :disabled="disabled" @click="back()">
        <v-icon :size="48">mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn tile depressed :height="64" :width="128" class="next-button" color="inherit" :disabled="!canNext" @click="next()">
        Confirm
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>
import { Api } from '../../js/api'
import captcha from './captcha.vue'

export default {
  name: 'Paypal',
  props: ['plan', 'preSubscribeMethod'],
  components: {
    captcha
  },
  data: () => ({
    confirmed: false,
    paypalId: '',
    paypalUrl: '',
    resolvedUrl: '',
  }),
  computed: {
    disabled () {
      return this.paypalFormCompleted || this.confirming;
    },
    captchaVerified () {
      return this.$store.getters.getCaptchaResponse != null;
    },
    canNext () {
      return this.captchaVerified && !this.confirmed;
    }
  },
  watch: {
  },
  methods: {
    getAgreement: function (planType) {
      let scope = this;
      return new Promise((resolve) => {
        Api.get("Subscription/paypalAgreement/" + planType).then(function (res) {
          if (res.valid) {
            scope.agreementRecieved = true;
            scope.paypalId = res.data.id;
            scope.paypalUrl = res.data.url;
            resolve()
          }
        })
      })
    },
    next: function () {
      let scope = this;
      scope.confirmed = true;
      scope.resolvePaypal()
    },  
    back: function () {
      this.$emit('back');
    },
    resolvePaypal: function () {
      let scope = this;
      scope.confirming = true;
      scope.preSubscribeMethod().then(function () {
        scope.getAgreement(scope.plan.selectedPlan.planType).then(function () {
          window.location = scope.paypalUrl;
        })
      })
    },
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.paypal-container {
  height: 400px;
}
.paypal-text {
  height: 200px;
}
.paypal-full-text {
  height: 400px;
}
</style>
