<template>
  <div>
    <form action="?" method="POST">
      <div id="signup-captcha" class="g-recaptcha">

      </div>
      <!-- <input type="submit" value="Submit"> -->
    </form>
  </div>
</template>
<script>
export default {
  name: 'Captcha',
  components: {
  },
  props: [],
  data: () => ({
    captchaWidgetId: null
  }),
  computed: {
    captchaKey () {
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    }
  },
  watch: {
  },
  methods: {
    setupCaptcha: function () {
      // eslint-disable-next-line
      var widgetId = grecaptcha.render(
        "signup-captcha",
        { 
          "sitekey": this.captchaKey, 
          "theme": "light",
          "callback": this.getCaptchaResponse,
          'expired-callback': this.expiredCallback,
          'error-callback': this.errorCallback
        }
      )
      this.captchaWidgetId = widgetId;
      this.$store.commit('setCaptchaResponse', null);
    },
    errorCallback: function () {
      this.$store.commit('setCaptchaResponse', null);
    },
    expiredCallback: function () {
      this.$store.commit('setCaptchaResponse', null); 
    },
    getCaptchaResponse: function () {
      // eslint-disable-next-line
      var captchaResponse = grecaptcha.getResponse(this.captchaWidgetId);
      this.$store.commit('setCaptchaResponse', captchaResponse);
    }
  },
  mounted () {
    this.setupCaptcha();
  }
}
</script>
<style lang="scss">
.g-recaptcha > div {
  margin: 0 auto;
}
</style>