<template>
  <v-card flat color="#00000000" class="plans-card">
    <v-card-title class="register-title px-0 text-center" v-html="title">
    </v-card-title>
    <v-card-subtitle class="plans-subtitle pt-2 font-italic">
      <i><span class="primary--text">+</span> your name listed in Disrupt videos </i>
    </v-card-subtitle>
    <v-card-text class="plan-card-text" v-bind:class="{ 'mobile-plans': isMobile }">
      <v-list class="plan-list">
        <v-list-item-group v-model="plan.selectedPlan" active-class="active-plan">
          <v-list-item v-for="plan in plans" :value="plan" :key="plan.title" class="plan-item pr-0 my-4">
            <v-list-item-content>
              <v-list-item-title class="plan-title" v-text="plan.title"></v-list-item-title>
              <!-- <v-list-item-subtitle class="sub-title"  v-text="plan.subtitle"></v-list-item-subtitle> -->
              <v-list-item-subtitle class="sub-title">
                <v-chip v-for="subtitle in plan.subtitles" :key="subtitle.text" :color="subtitle.color" text-color="black" small class="plan-chip">
                  {{ subtitle.text }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar :size="100" tile class="price-box text-center" v-bind:style="{'background-color': plan.priceColor}">
              <span class="plan-price">{{plan.currency}}{{plan.price}}</span>
              <span class="plan-interval">{{plan.interval}}</span>
            </v-list-item-avatar>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-spacer/>
      <v-text-field label="coupon" class="square-fields coupon" v-bind:class="{ 'coupon-active-plan': plan.selectedPlan && plan.selectedPlan.title == 'Coupon' }"
       v-model="coupon" @focus="couponChanged" @change="couponChanged" height="32" flat solo></v-text-field>
      <v-spacer/>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn tile depressed :height="64" :width="64" @click="back()">
        <v-icon :size="48">mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn tile depressed :height="64" :width="128" class="next-button" color="inherit" :disabled="!canNext" @click="next()">
        Next
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'Plans',
  components: {
  },
  props: ['plan', 'upgrading'],
  data: () => ({
    coupon: ''
  }),
  computed: {
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
    title () {
      return 'Become a Producer, <br> unlock more content';
    },
    plans () {
      let plans = [
        {
          title: 'Producer',
          subtitles: [
            {
              text: 'Free Steam game every 30 Days',
              color: '#ffe500'
            }
          ],
          currency: '$',
          price: process.env.VUE_APP_MONTHLY_PRICE,
          type: 'premium',
          interval: '/month',
          priceColor: 'var(--v-primary-base)',
          planType: 'monthly'
        },
        {
          title: 'Yearly Producer',
          subtitles: [
            {
              text: 'Free Steam game every 30 Days',
              color: '#ffe500'
            }
          ],
          currency: '$',
          price: process.env.VUE_APP_YEARLY_PRICE,
          type: 'premium',
          interval: '/year',
          priceColor: 'var(--v-primary-base)',
          planType: 'yearly'
        },
        // {
        //   title: '1-Time Patron',
        //   subtitles: [
        //     {
        //       text: '30 Day Access',
        //       color: 'white'
        //     },
        //   ],
        //   subtitle: 'All-access',
        //   currency: '$',
        //   price: process.env.VUE_APP_MONTH_PRICE,
        //   type: 'premium',
        //   interval: '',
        //   priceColor: 'var(--v-primary-base)',
        //   planType: 'month'
        // }
      ]
      if (!this.upgrading) {
        plans.push({
          title: 'Basic',
          subtitles: [
            {
              text: 'Limited Access',
              color: 'white'
            },
          ],
          currency: '$',
          price: '0',
          type: 'basic',
          interval: '',
          priceColor: 'grey',
          planType: 'free'
        })
      }
      return plans;
    },
    canNext () {
      if (this.plan.selectedPlan != null) {
        if (this.plan.selectedPlan.title == 'Coupon') {
          return this.coupon.length > 5;
        } else {
          return true;
        }
      }
      return false;
    },
    isCoupon () {
      if (this.plan.selectedPlan && this.plan.selectedPlan.title == 'Coupon') {
        return true;
      }
      return false;
    }
  },
  watch: {
    coupon: function () {
      this.couponChanged();
    }
  },
  methods: {
    next: function () {
      this.$emit('next');
    },
    back: function () {
      this.$emit('back');
    },
    couponChanged: function () {
      this.plan.selectedPlan = {
        title: 'Coupon',
        planType: 'coupon',
        code: this.coupon
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
.plans-card {
}

.register-title { 
  word-break: break-word;
}
.plan-list {
  background: none !important;
}
.plan-item {
  background-color: #282828;
  letter-spacing: 0px;
}
.active-plan {
  letter-spacing: 0px;
  border: 4px solid var(--v-primary-base);
}
.active-plan .v-list-item__content,
.active-plan .price-box{
  margin: -4px;
}
.plan-title {
  font-size: 32px
}
.mobile-plans .plan-title {
  font-size: 24px !important;
}

.plans-subtitle {
  text-align: center;
  color: white !important;
  font-size: 16px;
}
.plan-chip {
  border-radius: 10px !important;
  margin-right: 8px;
  font-size: 11px !important;
}
.price-box {
  width: 100px !important;
  height: 100px !important;
  margin: 0px;
  padding: 16px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.plan-price {
  font-size: 24px;
}
.plan-interval {
  font-size: 12px;
  letter-spacing: 0px;
}
.coupon {
  width: 200px;
  margin: 0 auto !important;
}
.coupon-active-plan .v-input__slot {
  letter-spacing: 0px;
  border: 4px solid var(--v-primary-base);
}
.plan-card-text {
  // max-height: calc(100vh - 260px);
  // overflow-y: auto;
}

.coupon .v-label {
  color: gray !important;
  padding-left: 0px !important;
}
</style>