<template>
  <v-card flat color="#00000000">
    <v-container v-if="verifying" class="coupon-body">
      <v-row align="center" class="text-center">
        <v-col>
          Verifying Coupon
        </v-col>
      </v-row>
      <v-row align="center" class="text-center">
        <v-col>
          <v-progress-circular :size="80" :width="2" color="primary" class="site-loader" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else class="coupon-body">
      <v-row align="center" class="text-center">
        <v-col v-if="couponVerified">
          <div class="coupon-body-title">
            Coupon is Valid <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </div>
          <div class="coupon-body-text">
            {{ coupon.days }} Days of Disrupt Premium
          </div>
          <div class="pa-2">
            <captcha />
          </div>
        </v-col>
        <v-col v-else class="coupon-body-text">
          Coupon is invalid
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer/>
      <v-btn tile depressed :height="64" :width="64" :disabled="disabled || verifying" @click="back()">
        <v-icon :size="48">mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn tile depressed :height="64" class="next-button" color="inherit" :disabled="disabled || verifying || !canNext" :loading="loading" @click="next()">
        Use Coupon
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>

import { Api } from '../../js/api'
import captcha from './captcha.vue'

export default {
  name: 'Coupon',
  props: ['plan', 'preSubscribeMethod'],
  components: {
    captcha
  },
  data: () => ({
    disabled: false,
    loading: false,
    verifying: false,
    couponVerified: false,
    coupon: null
  }),
  computed: {
    captchaVerified () {
      return this.$store.getters.getCaptchaResponse != null;
    },
    canNext () {
      return this.couponVerified && this.captchaVerified;
    }
  },
  watch: {
  },
  methods: {
    next: function () {
      let scope = this;
      scope.disabled = true;
      scope.loading = true;

      // register the user
      scope.preSubscribeMethod().then(function () {
        
        let code = scope.plan.selectedPlan.code
        Api.post('Subscription/useCoupon', code).then(function (subRes) {
          if (subRes.valid) {
            // subscribed successfully
            scope.$emit('finish')
          } else {
            // something went wrong
            scope.$emit('reset')
          }
        })
      })
    },
    verifyCoupon: function () {
      let scope = this;

      let code = this.plan.selectedPlan.code
      scope.verifying = true;
      scope.couponVerified = false;
      
      setTimeout(function () {
        Api.post('Subscription/checkCoupon', code).then(function (res) {
          if (res.data) {
            scope.verifying = false;
            scope.couponVerified = true;
            scope.coupon = res.data;
          } else {
            scope.verifying = false;
            scope.couponVerified = false;
          }
        })
      }, 1000)
    },
    back: function () {
      this.$emit('back');
    },
  },
  mounted () {
    this.couponVerified = false;
    this.verifyCoupon();
  }
}
</script>
<style lang="scss" scoped>
.coupon-body {
  height: 200px;
}
.coupon-body-title {
  font-size: 32px;
}
.coupon-body-text {
  font-size: 24px;
}
</style>
