<template>
  <v-card flat color="#00000000">
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 class="stripe-title mb-2">
          Stripe (credit / debit)
          <span class="ml-2 red--text">
            {{ stripeError }}
          </span>
        </v-flex>
        <v-flex xs12>
          <v-text-field id="card-name-element" class="square-fields" maxlength="128" :disabled="disabled" v-model="name" placeholder="Name on card" flat solo />
        </v-flex>
        <v-flex xs12 id="card-element" class="mb-2">
        </v-flex>
        <v-flex>
          <captcha />
        </v-flex>
      </v-layout>
    </v-container>
    <v-card-actions>
      <v-spacer/>
      <v-btn tile depressed :height="64" :width="64" :disabled="disabled" @click="back()">
        <v-icon :size="48">mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn tile depressed :height="64" :width="128" class="next-button" color="inherit" :disabled="disabled || !canNext" :loading="loading" @click="next()">
        Pay
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>
// import { loadStripe } from '@stripe/stripe-js';
import captcha from './captcha.vue'

export default {
  name: 'Stripe',
  props: ['plan', 'preSubscribeMethod'],
  components: {
    captcha
  },
  data: () => ({
    disabled: false,
    loading: false,
    paymentCreated: false,
    name: '',
    stripe: null,
    cardElement: null,
    stripeError: '',
  }),
  computed: {
    detailsValid () {
      if (this.name != '') {
        return true;
      }
      return false;
    },
    captchaVerified () {
      return this.$store.getters.getCaptchaResponse != null;
    },
    canNext () {
      return this.detailsValid && this.captchaVerified;
    }
  },
  watch: {
  },
  methods: {
    next: function () {
      let scope = this;
      scope.disabled = true;
      scope.loading = true;

      if(!scope.paymentCreated) {
        // create the stripe payment
        scope.stripe.createPaymentMethod({
          type: 'card',
          card: scope.cardElement,
          billing_details: {
            name: scope.name,
          }
        }).then(function (res) {
          if (res.error) {
            console.log(res.error.message);
            scope.stripeError = res.error.message;
            scope.disabled = false;
            scope.loading = false;
          } else {
            scope.stripeError = '';

            // register the user
            scope.preSubscribeMethod().then(function () {
              let paymentMethod = res.paymentMethod;

              let subData = {
                planType: scope.plan.selectedPlan.planType,
                paymentType: 'stripe',
                planId: '',
                paymentKey: paymentMethod.id
              }

              scope.$store.dispatch('subscribe', subData).then(function (subRes) {
                if (subRes.valid) {
                  // subscribed successfully
                  scope.$emit('finish')
                } else {
                  // something went wrong
                  scope.$emit('reset')
                }
              })
            })
          }
        })
      }
    },
    back: function () {
      this.$emit('back');
    },
    numberFocused: function () {
      let inputParent = this.$refs['number-field'].$el;
      let input = inputParent.getElementsByTagName('input')[0];
      input.focus();
    },
    setupStripe: function () {
      // eslint-disable-next-line
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

      const elements = this.stripe.elements();

      const cardElement = elements.create('card',  {
        style: {
          base: {
            backgroundColor: "#282828",
            color: "white",
            padding: '16',
            fontWeight: 500,
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "#CFD7DF"
            }
          },
          invalid: {
            color: "#E25950"
          }
        }
      });
      cardElement.mount('#card-element');
      this.cardElement = cardElement;
    }
  },
  mounted () {
    this.setupStripe();
  }
}
</script>
<style lang="scss" scoped>
.stripe-title {
}
.hidden-number-field {
  height: 0px !important;
  overflow: hidden;
}
#card-element {
  background-color: #282828;
  padding: 16px;
}
</style>
